<template>
    <main :style="displayStyle" class="extrainfo">
      <slot name="default"></slot>
    </main>
    <header @click="toggle" class="read-more-header">
      <span v-html="headerText"></span>
    </header>
</template>

<script>
export default {
  name: 'ReadMore',
  props: ['topic'],
  data() {
    return {
      open: false,
      headerText: 'read more...'
    }
  },
  methods: {
    toggle() {
      this.open = !(this.open)
      if (this.open) {
        this.headerText = 'read less'

      } else {
        this.headerText = 'read more...'
      }
    },
    reset() {
      this.open = false
      this.headerText = 'read more...'
    }
  },
  computed: {
    displayStyle() {
      return this.open ? "display: block" : "display: none"
    }
  },
  watch: {
    topic: function() {
      this.reset()
    }
  }
}
</script>


<style>



h2{
  margin: 10px 0px;
  font-size: larger;
  text-align: left;
}

.arrow{
  margin-right: 8px;
}

</style>